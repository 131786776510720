import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

function Nav(props) {
  const { menu } = props;

  return (
    <nav aria-label="Main" className="nav-header">
      <MenuPrimary>
        {menu.map((itemMenu, index) => (
          <LiMenu key={index}>
            <Link
              activeClassName="active"
              forceInternal={itemMenu.activeInternal}
              to={itemMenu.url}
            >
              {itemMenu.name}

            </Link>
          </LiMenu>
        ))}
      </MenuPrimary>
    </nav>
  );
}

Nav.propTypes = {
  menu: PropTypes.array,
};

Nav.defaultProps = {
  menu: '',
};

export default Nav;

const MenuPrimary = styled.ul`
  display: inline-block;
  margin: 0;
`;
const LiMenu = styled.li`
  display: inline-block;
  padding-right: 16px;
  margin: 0;
  a {
    color: ${({ theme }) => theme.white};
    padding: 0 8px 8px;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;
    &:hover, &.active{
      border-color: ${({ theme }) => theme.white};
      transition: all 0.3 ease;
    }
  }
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    display: block;
    padding-top: 20px;
    a {
      color: ${({ theme }) => theme.black};
    }
  }
`;
