import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ButtonLink from '../buttonLink';
import InnerHTML from '../innerHTML';
import { WrapperInner } from '../style.js';

function RichText(props) {
  const { title, description, buttonLinks } = props;

  return (
    <RichTextWrapper>
      <WrapperInner>
        {!! title && <h2>{title}</h2>}
        {!! description && (
          <Description>
            <InnerHTML content={description} />
          </Description>
        )}
        {buttonLinks && buttonLinks.length ? (
          <ButtonWrapper>
            {buttonLinks.map((itemLink, index) => (
              <ButtonLink key={index} text={itemLink.text} url={itemLink.url} type={itemLink.type} />
            ))}
          </ButtonWrapper>
        ) : null}
      </WrapperInner>
    </RichTextWrapper>
  );
}

export default RichText;

RichText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLinks: PropTypes.array,
};

RichText.defaultProps = {
  title: '',
  description: '',
  buttonLinks: [],
};

const RichTextWrapper = styled.div`
  margin: 6rem auto;
  position: relative;
  width: 100%;
`;
const Description = styled.div`
  color: ${({ theme }) => theme.gray};
  line-height: 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.device.tabletMiniMediaMax}) {
    flex-direction: column;
  }
`;
