/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import Nav from './nav';
import Image from './image';
import { WrapperInner } from './style.js';
import Search from '../../static/images/Kchannels_search.svg';
import SocialLinkItem from './socialLinkItem';

function Header(props) {
  const { menu, socialLinkList, logo } = props;
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenuMobile = () => {
    setShowMenu(! showMenu);
  };

  return (
    <HeaderWrapper
      className={classNames({
        active: showMenu,
      })}
    >
      <HeaderContainer>
        <LogoHeaderWrapper>
          <Image src={logo.src} url={logo.url} title={logo.title} />
        </LogoHeaderWrapper>

        <SideWrapper className="sideMenu">
          <SideLogo>
            <Image src={logo.src} url={logo.url} title={logo.title} />
          </SideLogo>
          <IconCloseMenu onClick={toggleMenuMobile} />
          <Nav menu={menu} />
        </SideWrapper>

        <RightMenuActionWrapper>
          {socialLinkList.map((item, index) => (
            <SocialLinkItem
              key={index}
              name={item.name}
              url={item.url}
              type={item.type}
            />
          ))}

          <button type="button">
            <span>
              <Search />
            </span>
          </button>

          <ToggleMobileMenu onClick={toggleMenuMobile}>
            <span />
          </ToggleMobileMenu>
        </RightMenuActionWrapper>
      </HeaderContainer>
      <NavMaskModal
        className="navMask"
        onClick={toggleMenuMobile}
      />
    </HeaderWrapper>
  );
}

export default withTheme(Header);

Header.propTypes = {
  menu: PropTypes.array,
  logo: PropTypes.object,
  socialLinkList: PropTypes.array,
};

Header.defaultProps = {
  menu: '',
  logo: '',
  socialLinkList: '',
};

const HeaderWrapper = styled.header`
  position: absolute;
  z-index: 10;
  width: 100%;
  &.active {
    .sideMenu {
      transform: translate(250px);
    }
    .navMask {
      opacity: 1;
      visibility: visible;
    }
  }
`;
const HeaderContainer = styled(WrapperInner)`
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  padding: 30px 20px;
`;
const LogoHeaderWrapper = styled.div`
  margin-right: auto;
  img {
    margin-bottom: 0;
    max-height: 60px;
  }
`;
const SideWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    z-index: 9999;
    width: 250px;
    height: 100%;
    top: 0;
    left: -250px;
    position: fixed;
    overflow: hidden;
    overflow-y: auto;
    background: #fff;
    transition-duration: 0.3s;
    transform: translate(0);
    transition-duration: 0.3s;
    padding: 1rem;
  }
`;
const SideLogo = styled.div`
  display: none;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    display: block;
  }
`;
const ToggleMobileMenu = styled.span`
  display: none;
  content: "";
  height: 25px;
  width: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: 1rem;
  &:before,
  &:after,
  span {
    content: "";
    display: block;
    height: 2px;
    margin: 4px 0 !important;
    transition: all 0.2s ease-in-out;
    background: #fff;
  }
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    display: block;
  }
`;
const RightMenuActionWrapper = styled.div`
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  button {
    background: transparent;
    border: none;
    padding-right: 0;
  }
  img {
    margin-bottom: 0;
  }
  a {
    path{
      fill: #FFF;
    }
  }
`;
const NavMaskModal = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  opacity: 0.1;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 999;
  position: fixed;
  transition: all 0.2s ease-in-out;
  transition-duration: 0.1s;

  ${'' /* opacity: 1;
  visibility: visible; */}
`;
const IconCloseMenu = styled.span`
  content: "";
  width: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: none;
  &:before,
  &:after,
  span {
    content: "";
    display: block;
    height: 2px;
    margin: 4px 0;
    transition: all 0.2s ease-in-out;
    background: #000;
  }
  &:before {
    transform: translateY(3px) rotate(135deg);
  }
  &:after {
    transform: translateY(-3px) rotate(-135deg);
  }
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    display: block;
  }
`;
