import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Image from '../image';
import InnerHTML from '../innerHTML';
import { WrapperInner } from '../style.js';

function Feature(props) {
  const {
    title, description, content, imgURL, customCss,
  } = props;

  return (
    <FeatureWrapper>
      <WrapperInnerCustom>
        <FeatureContent>
          {!! imgURL && (
            <FeatureLeft
              className={classNames({
                customImg: customCss,
              })}
            >
              <Image src={imgURL} alt={title} />
            </FeatureLeft>
          )}
          <FeatureRight>
            {!! title && <h2>{title}</h2>}
            {!! description && (
              <Description>
                <InnerHTML content={description} />
              </Description>
            )}
            {!! content && (
              <Content>
                <InnerHTML content={content} />
              </Content>
            )}
          </FeatureRight>
        </FeatureContent>
      </WrapperInnerCustom>
    </FeatureWrapper>
  );
}

Feature.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  imgURL: PropTypes.string,
  customCss: PropTypes.bool,
};

Feature.defaultProps = {
  title: '',
  description: '',
  content: '',
  imgURL: '',
  customCss: false,
};

export default Feature;

const FeatureWrapper = styled.div`
  margin: 6rem auto;
  position: relative;
  width: 100%;
`;

const FeatureContent = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    flex-direction: column-reverse;
  }
`;
const FeatureLeft = styled.div`
  flex: 1;
  position: relative;
  img {
    padding-right: 1rem;
  }
  &.customImg img {
    padding-left: 4rem;
  }
  @media (max-width: ${({ theme }) => theme.device.tabletMediaMax}) {
    width: 100%;
    img {
      position: relative;
      top: 0;
      padding-right: 0;
    }
    &.customImg img {
      left: 0;
    }
  }
`;
const FeatureRight = styled.div`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.device.desktop}) {
    width: 600px;
  }
  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    width: 50%;
  }
`;
const Description = styled.div`
  color: ${({ theme }) => theme.gray};
  line-height: 24px;
`;
const Content = styled.div`
  margin-top: 32px;
  line-height: 1.5rem;
`;

const WrapperInnerCustom = styled(WrapperInner)`
  @media (min-width: ${({ theme }) => theme.device.tablet}) {
    margin-right: calc((100vw - 80vw) / 2);
    max-width: 100%;
    width: auto;
    padding-left: 0;
  }
  @media (min-width: 1450px) {
    margin-right: calc((100vw - 1160px) / 2);
  }
`;
