import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { WrapperInner } from '../style.js';
import TestimonialItem from '../testimonialItem';

function Testimonial(props) {
  const { title, items } = props;

  return (
    <TestimonialWrapper>
      <WrapperInner>
        {!! title && <h2>{title}</h2>}
        {items && items.length ? (
          <WrapperListing>
            {items.map((item) => (
              <TestimonialItem
                key={item.id}
                avatarURL={item.avatarURL}
                name={item.name}
                url={item.url}
                headline={item.headline}
                about={item.about}
                socialLinks={item.socialLinks}
              />
            ))}
          </WrapperListing>
        ) : null}
      </WrapperInner>
    </TestimonialWrapper>
  );
}

Testimonial.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

Testimonial.defaultProps = {
  title: '',
  items: '',
};

export default Testimonial;

const TestimonialWrapper = styled.div`
  margin: 6rem auto;
  position: relative;
  width: 100%;
`;
const WrapperListing = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-flow: wrap;
  margin: -1.25rem;
`;
