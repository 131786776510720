import PropTypes from 'prop-types';
import React from 'react';

function InnerHTML(props) {
  const { content } = props;

  return (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: content }} />
  );
}

InnerHTML.propTypes = {
  content: PropTypes.string,
};

InnerHTML.defaultProps = {
  content: '',
};

export default InnerHTML;
