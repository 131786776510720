/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Image from '../image';
import { WrapperInner } from '../style.js';

function HeroCard(props) {
  const { title, imgUrl } = props;
  return (
    <HeroWrapper>
      {!! imgUrl && (
        <HeroBgImage>
          <Image src={imgUrl} />
        </HeroBgImage>
      )}
      {!! title && (
        <HeroContentWrapper>
          <h1>{title}</h1>
        </HeroContentWrapper>
      )}
    </HeroWrapper>
  );
}

HeroCard.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
};

HeroCard.defaultProps = {
  title: '',
  imgUrl: '',
};

export default HeroCard;

const HeroWrapper = styled.div`
  min-height: 42rem;
  position: relative;
  display: flex;
  align-items: center;
`;
const HeroBgImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  img {
    position: absolute;
    background-size: cover;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;
const HeroContentWrapper = styled(WrapperInner)`
  position: relative;
  z-index: 2;
  h1 {
    color: ${({ theme }) => theme.white};
    @media (min-width: ${({ theme }) => theme.device.desktop}) {
      max-width: 75%;
    }
  }
`;
