/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const WrapperInner = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  width: 80vw;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.device.tabletMiniMediaMax}) {
    width: 100%;
  }
`;
