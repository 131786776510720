import React from 'react';
import data from '../../data/home';
import Layout from '../components/layout';
import toElement from '../utils/toElement';
import headerData from '../../data/header';
import SEO from '../components/seo';
function HomePage() {
  return (
    <Layout>
      <SEO title="Kchannels" pagePath="/" />
      {toElement(headerData)}
      <div className="bodyMain">
        {data && data.length ? data.map((item, index) => toElement(item, index)) : null}
      </div>
      {/* Footer */}
    </Layout>
  );
}

export default HomePage;

HomePage.propTypes = {};
