import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'gatsby';

function DefaultLink(props) {
  const {
    newTab,
    children,
    to,
    activeInternal,
    ...rest
  } = props;

  // checks if relative url "about/" or "/about/"
  // otherwise treats as external link
  const isInternal = /^\/(?!\/)/.test(to);
  const newTabHtmlAttributes = {
    target: ! activeInternal && (newTab || ! isInternal) ? '_blank' : null,
    rel: newTab ? 'noopener' : null,
  };

  return isInternal ?
    (
      <Link
        to={to}
        {...newTabHtmlAttributes}
        {...rest}
      >
        {children}
      </Link>
    ) :
    (
      <a
        style={{ textDecoration: 'none' }}
        href={to}
        {...newTabHtmlAttributes}
        {...rest}
      >
        {children}
      </a>
    );
}

export default DefaultLink;

DefaultLink.propTypes = {
  to: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  children: PropTypes.node,
  activeInternal: PropTypes.bool,
};

DefaultLink.defaultProps = {
  newTab: false,
  children: '',
  activeInternal: false,
};
