import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import CardThreeItem from '../cardThreeItem';
import { WrapperInner } from '../style.js';

function CardThree(props) {
  const { title, description, items } = props;

  return (
    <CardThreeWrapper>
      <WrapperInner>
        {!! title && <WrapperTitle>{title}</WrapperTitle>}
        {!! description && (
          <Description>
            {description}
          </Description>
        )}
        {items && items.length ? (
          <WrapperContent>
            <CardList>
              {items.map((item) => (
                <CardThreeItem
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  url={item.url}
                  description={item.description}
                />
              ))}
            </CardList>
          </WrapperContent>
        ) : null}
      </WrapperInner>
    </CardThreeWrapper>
  );
}

CardThree.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.array,
};

CardThree.defaultProps = {
  title: '',
  description: '',
  items: '',
};

export default CardThree;

const CardList = styled.div`
  display: flex;
  flex-flow: wrap;
  margin: -1.25rem;
`;

const CardThreeWrapper = styled.div`
  margin: 6rem auto;
  position: relative;
  width: 100%;
`;
const Description = styled.div`
  color: ${({ theme }) => theme.gray};
  line-height: 24px;
  text-align: center;
`;
const WrapperTitle = styled.h2`
  text-align: center;
`;
const WrapperContent = styled.div`
  margin-top: 48px;
`;
