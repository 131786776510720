import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import globalTheme from '../lib/theme';
import './layout.css';

function Layout(props) {
  // eslint-disable-next-line react/prop-types
  const { children, theme = {} } = props;
  return (
    <ThemeProvider theme={{ ...globalTheme, ...theme }}>
      <Helmet
        title="Kchannels"
        meta={[
          {
            name: 'description',
            content: 'description',
          },
          {
            name: 'keywords',
            content: 'blockchain, entrepreneurs, innovation, venture studio',
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      {children}
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
