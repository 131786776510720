import React from 'react';
import PropTypes from 'prop-types';

function NotConfigured(props) {
  const { children, name } = props;

  return (
    <>
      <h1>
        Component
        {`"${name}"`}
        is not configured.
      </h1>
      {children}
    </>
  );
}

NotConfigured.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default NotConfigured;
