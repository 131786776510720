export default {
  name: 'header',
  config: {
    menu: [
      { id: 1, name: 'Home', url: '/' },
      { id: 2, name: 'Blog', url: 'https://medium.com/kchannels' },
      { id: 3, name: 'Docs', url: 'https://docs.kchannels.io/' },
      {
        id: 4, name: 'Contact Us', url: 'mailto:hello@kchannels.io', activeInternal: true,
      },
    ],
    socialLinkList: [
      { name: 'Telegram', url: 'https://t.me/kchannels', type: 'telegram' },
      { name: 'Twitter', url: 'https://twitter.com/kchannelsio', type: 'twitter' },
    ],
    logo: {
      title: 'Kchannels Logo',
      src: '/images/Kchannels_Logo.png',
      url: '/',
    },
  },
};
