import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

function CardThreeItem(props) {
  const {
    id,
    title,
    url,
    description,
  } = props;

  return (
    <CardItem>
      {!! id && <CardId>{id}</CardId>}
      {!! title && (
        <CardTitle>
          {url ? (
            <Link to={url}>
              <h5>{title}</h5>
            </Link>
          ) : (
            <h5>{title}</h5>
          )}
        </CardTitle>
      )}
      {!! description && <CardDes>{description}</CardDes>}
    </CardItem>
  );
}

CardThreeItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
};

CardThreeItem.defaultProps = {
  id: '',
  title: '',
  url: '',
  description: '',
};

export default CardThreeItem;

const CardItem = styled.div`
  text-align: center;
  padding: 1.25rem 2.25rem;
  width: 33.3%;
  @media (max-width: ${({ theme }) => theme.device.tabletMiniMediaMax}) {
    width: 50%;
  }
  @media (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 100%;
  }
`;
const CardId = styled.div`
  width: 90px;
  height: 90px;
  margin: 0 auto;
  background: #ec4c4c;
  font-size: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  line-height: 48px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 24px;
  padding-right: 5px;
`;
const CardTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 16px;
  h5 {
    color: ${({ theme }) => theme.gray};
  }
`;
const CardDes = styled.div`
  color: ${({ theme }) => theme.gray};
  line-height: 24px;
`;
