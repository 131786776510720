/* eslint-disable max-len */
export default [
  {
    name: 'hero-card',
    config: {
      title:
        'A payment channel system for Ethereum with great UX and fast finality',
      imgUrl: 'images/Kchannels_Hero_Bg.png',
    },
  },
  {
    name: 'rich-text',
    config: {
      title: 'Layer2 for the people',
      description:
        '<p>Kchannels is a new payment channel platform for the Ethereum blockchain.  It is non-custodial and trust-minimized, and its primary focus is on great UX and instant off-chain finality.  Among other things, it is well suited for commerce use-cases (e.g. merchants and their customers).</p><p> Kchannels is available on Mainnet (and testnets) *today* with support for Ether and ERC-20 tokens.</p>',
      buttonLinks: [
        {
          text: 'Join our Telegram',
          url: 'https://t.me/kchannels',
          type: 'telegram',
        },
        {
          text: 'Follow us on Twitter',
          url: 'https://twitter.com/kchannelsio',
          type: 'twitter',
        },
      ],
    },
  },
  {
    name: 'feature',
    config: {
      title: 'Multi-Channels: A New Channel Construction',
      description:
        '<p>Kchannels is a new payment channel platform for the Ethereum blockchain.  It is non-custodial and trust-minimized, and its primary focus is on great UX and instant off-chain finality.  Among other things, it is well suited for commerce use-cases (e.g. merchants and their customers). </p><p> Kchannels is available on Mainnet (and testnets) *today* with support for Ether and ERC-20 tokens.</p>',
      content:
        '<p><strong>A channel can hold multiple assets,</strong> and it can send and receive multiple assets in a single transaction.</p><p><strong>An unattended channel can still receive transactions,</strong> and all funds within it are safe.  No need to watch the blockchain! </p><p><strong>Deposits and withdrawals to/from Layer1 are fast and easy</strong>--no additional waiting beyond the time it takes to mine a block. </p><p><strong>Most users only need a single channel--ever--for all their needs!</strong>  A channel is free to create, and it only takes an instant.</p>',
      imgURL: 'images/Kchannels_Feature_Multichannel.png',
    },
  },
  {
    name: 'rich-text',
    config: {
      title: 'Fast Finality',
      description:
        '<p>Finality on Layer2 is hard; many Layer2 solutions can\'t guarantee that your transaction is confirmed and final for a long time after it seemingly completes.  Others achieve finality by imposing significant requirements on their users, such as the need to monitor the blockchain at all times until the final on-chain settlement.  In Kchannels a Layer2 transaction is truly final and confirmed as soon as it completes--instant off-chain finality for a very credit card-like experience--without imposing any additional burdens on the user. </p><p> Fast finality is an absolute necessity for many important use-cases that are currently not supported by the Ethereum ecosystem.</p>',
    },
  },
  {
    name: 'card-three',
    config: {
      title: 'Designed from the ground up for great UX',
      description:
        'Imagine transacting in Web3 with the UX of Web2.  Transact in Layer2 as if you were hitting the Stripe API; you make a simple call and you\'re done.',
      items: [
        {
          id: 1,
          url: '',
          title: 'Minimal Infastructure Requirements',
          description: 'No need to install any software, not even Geth.',
        },
        {
          id: 2,
          url: '',
          title: 'No Special Token',
          description: 'There is no special token anywhere in the system.',
        },
        {
          id: 3,
          url: '',
          title: 'No Network',
          description:
            'Kchannels avoids all the complexity of routing transactions through a public Layer2 network.',
        },
        {
          id: 4,
          url: '',
          title: 'No Channel Balancing',
          description:
            'Transactions are not bottlenecked by the need for funds elsewhere in the system; your transaction is always delivered with no intermediaries.',
        },
        {
          id: 5,
          url: '',
          title: 'High Scalability',
          description:
            'Kchannels can scale horizontally to support any throughput desired simply by adding additional hardware.',
        },
        {
          id: 6,
          url: '',
          title: 'Channels Are Free',
          description:
            'Channels are fast and free to open as they do not require transactions on Layer1.  They also do not need to be funded to stay open.',
        },
      ],
    },
  },
  {
    name: 'feature',
    config: {
      title:
        '...And everything you should expect from a payment channel solution',
      content:
        '<p><strong>Fast Transaction:</strong> No more waiting for blocks/confirmations; no more dealing with congestion.</p><p><strong>Low Fee:</strong> No more dealing with expensive and ever-changing gas prices.</p><p><strong>Privacy:</strong> In-channel transactions are not visible on-chain.</p>',
      imgURL: 'images/Kchannels_Feature_Payment.png',
      customCss: true,
    },
  },
  {
    name: 'testimonial',
    config: {
      title: 'Team',
      items: [
        {
          id: 1,
          avatarURL: 'images/Kchannels_Testimonial_Kamen.png',
          name: 'Kamen',
          url: '',
          headline: 'Co-Founder',
          about: 'The "K" in Kchannels',
          socialLinks: [
            {
              name: 'Twitter',
              url: '',
              type: 'twitter',
            },
            {
              name: 'LinkedIn',
              url: '',
              type: 'linkedin',
            },
          ],
        },
        {
          id: 2,
          avatarURL: 'images/Kchannels_Testimonial_Maurycy.png',
          url: '',
          name: 'Maurycy',
          headline: 'Co-Founder',
          about: 'Previously Co-founder of <a href="https://infura.io/" target="_blank"> Infura </a>',
          socialLinks: [
            {
              name: 'Twitter',
              url: 'https://twitter.com/maurycy',
              type: 'twitter',
            },
            {
              name: 'LinkedIn',
              url: '',
              type: 'linkedin',
            },
          ],
        },
      ],
    },
  },
];
