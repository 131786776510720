import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Linkedin from '../../../static/images/Kchannels_Testimonial_LinkedIn.svg';
import Telegram from '../../../static/images/Kchannels_telegram.svg';
import Twitter from '../../../static/images/Kchannels_twitter.svg';

function Icon(props) {
  const {
    type,
  } = props;

  return (
    <SocialIcon>
      { 'linkedin' === type && <Linkedin /> }
      { 'telegram' === type && <Telegram /> }
      { 'twitter' === type && <Twitter /> }
    </SocialIcon>
  );
}

Icon.propTypes = {
  type: PropTypes.string,
};

Icon.defaultProps = {
  type: '',
};

export default Icon;

const SocialIcon = styled.span`
    cursor: pointer;
    
    &:hover{
      path{
          fill: ${({ theme }) => theme.primary};
      }
    }
`;
