import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Link from '../Link';
import Icon from '../icon';

function ButtonLink(props) {
  const { text, url, type } = props;

  return (
    <CTA to={url} title={text}>
      {!! type && <Icon type={type} />}
      <span>{text}</span>
    </CTA>
  );
}

ButtonLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

ButtonLink.defaultProps = {
  text: '',
  url: '',
  type: '',
};

export default ButtonLink;

const CTA = styled(Link)`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  padding: 20px 25px;
  border-radius: 3px;
  margin-right: 15px;
  margin-top: 15px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
  span {
    margin: 0 5px;
    &:hover{
      path{
        fill: ${({ theme }) => theme.white};
      }
    }
  }
  &:hover {
    opacity: 0.5;
  }
  @media (max-width: ${({ theme }) => theme.device.tabletMiniMediaMax}) {
    margin-right: 0;
  }
`;
