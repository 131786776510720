import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Image from '../image';
import SocialLinkItem from '../socialLinkItem';
import InnerHTML from '../innerHTML';

function TestimonialItem(props) {
  const {
    avatarURL,
    name,
    url,
    headline,
    about,
    socialLinks,
  } = props;

  return (
    <ItemOuter>
      {!! avatarURL && (
        <AvatarItem>
          <Image
            src={avatarURL}
            alt={name}
            url={url}
          />
        </AvatarItem>
      )}
      <ContentItem>
        {!! name && (
          <ItemName>{name}</ItemName>
        )}
        {!! headline && <ItemHeadline>{headline}</ItemHeadline>}
        {!! about && <ItemAbout><InnerHTML content={about} /></ItemAbout>}
        {!! socialLinks && (
          <SocialList>
            {socialLinks.map((item, index) => (
              <SocialLinkItem
                key={index}
                name={item.name}
                url={item.url}
                type={item.type}
              />
            ))}
          </SocialList>
        )}
      </ContentItem>
    </ItemOuter>
  );
}

TestimonialItem.propTypes = {
  avatarURL: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  headline: PropTypes.string,
  about: PropTypes.string,
  socialLinks: PropTypes.array,
};

TestimonialItem.defaultProps = {
  avatarURL: '',
  name: '',
  url: '',
  headline: '',
  about: '',
  socialLinks: '',
};

export default TestimonialItem;

const SocialList = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
`;
const ItemOuter = styled.div`
  width: 50%;
  padding: 1.25rem;
  align-items: flex-start;
  display: flex;
  flex-flow: wrap;
  @media (max-width: ${({ theme }) => theme.device.mobile}) {
    width: 100%;
  }
`;
const AvatarItem = styled.div`
  margin-right: 1.5rem;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
const ContentItem = styled.div`
  flex: 1;
  min-width: 0;
`;
const ItemName = styled.h6`
  margin-bottom: 1rem;
`;
const ItemHeadline = styled.div`
  margin-bottom: 1.5rem;
`;
const ItemAbout = styled.div`
  font-size: 13px};
  color: ${({ theme }) => theme.gray};
  margin-bottom: 12px;
  a{
    color: ${({ theme }) => theme.gray};
    text-decoration: underline;
  }
`;
