import CardThree from '../components/cardThree';
import Feature from '../components/feature';
import Header from '../components/Header';
import HeroCard from '../components/heroCard';
import Layout from '../components/layout';
import RichText from '../components/richText';
import Testimonial from '../components/testimonial';
import NotConfigured from '../components/notConfigureds';

const componentMap = {
  'card-three': CardThree,
  feature: Feature,
  header: Header,
  'hero-card': HeroCard,
  layout: Layout,
  'rich-text': RichText,
  testimonial: Testimonial,
};

export default function getComponent(name) {
  if (componentMap[name]) {
    return componentMap[name];
  }
  // Support standard html tag name.
  const VALID_TAG_REGEX = /^[a-zA-Z][a-zA-Z:_\.\-\d]*$/; // eslint-disable-line no-useless-escape
  if (VALID_TAG_REGEX.test(name)) {
    return name;
  }

  return NotConfigured;
}
