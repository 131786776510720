import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

function Image(props) {
  const { title, src, url } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {url && url.length ? (
        <Link to={url}>
          <Img title={title} alt={title} src={src} />
        </Link>
      ) : (
        <Img title={title} alt={title} src={src} />
      )}
    </>
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
};

Image.defaultProps = {
  url: '',
  title: '',
};

export default Image;

const Img = styled.img`
  margin-bottom: 0;
`;
