import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Icon from '../icon';
import Link from '../Link';

function SocialLinkItem(props) {
  const {
    name,
    url,
    type,
  } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!! url && (
        <SocialItem>
          <Link to={url} title={name}>
            <Icon type={type} />
          </Link>
        </SocialItem>
      )}
    </>
  );
}

SocialLinkItem.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

SocialLinkItem.defaultProps = {
  name: '',
  url: '',
  type: '',
};

export default SocialLinkItem;

const SocialItem = styled.div`
  margin-right: 16px;

  path{
    fill: ${({ theme }) => theme.gray};
  }
`;
